<template>
  <div>
    <div v-if="status">
      <div
        class="position-fixed top-0 left-0 right-0 bottom-0"
        style="background-color: rgba(0, 0, 0, 0.5); z-index: 99"
        @click.stop="hide"
      ></div>
      <div class="recharge animated faster fadeIn">
        <div class="recharge-title space-between">
          <div class="row-center">
            <img src="../../../assets/glxt/14.png" alt="" />
            <span class="text-main">确认支付</span>
          </div>
          <i class="el-icon-close pointer" @click="hide"></i>
        </div>
        <!-- <div class="recharge-text">
          用于置顶、刷新、人才推荐、急招等产品兑换，{{exchangeObj.coin}}纺职豆={{exchangeObj.price}}元
        </div> -->
        <div class="recharge-item">
          <div class="item-center direction-between">
            <div
              class="item flex-center pointer"
              v-for="(item, index) in rechargeList"
              :key="index"
              :class="activeIndex == index ? 'active-class' : ''"
              @click="activeClick(index, item.coin)"
            >
              充值{{ item.price }}，实得 <span>{{ item.coin }}</span> 个纺职豆
            </div>
            <div class="item space-between item-last">
              <div>支付金额</div>
              <span v-if="rechargeList.length > 0"
                >￥{{ rechargeList[activeIndex].price }}</span
              >
            </div>
          </div>
          <div class="tab-pay flex-center">
            <div
              class="flex-center pointer"
              :class="[payActiveIndex == 3 ? 'pay-item-active' : '']"
              @click="payClick(3)"
            >
              支付宝
            </div>
            <div
              class="flex-center pointer"
              :class="[payActiveIndex == 4 ? 'pay-item-active' : '']"
              @click="payClick(4)"
            >
              微信
            </div>
          </div>

          <div class="direction">
            <div class="flex-center" v-if="payActiveIndex == 3">
              <el-button type="primary" plain @click="zfbClick">点击去支付</el-button>
            </div>
            <div v-if="payActiveIndex == 4 && buyObj">
              <img :src="buyObj.payment.qrcode_url" class="img-sm" alt="" />
              <div class="sm-text flex-center">二维码有效期至</div>
              <div class="sm-text">{{buyObj.failure_time}}</div>
              <div class="sm-zfb flex-center">
                <img src="../../../assets/glxt/16.png" class="wx-img" alt="" />
                <span class="sm-text">扫码支付</span>
              </div>
            </div>
            <div class="sm-xy">
              付费即表示同意
              <span class="text-blue pointer" @click="serveClick">《干纺织招聘服务协议》</span>
            </div>
            <div class="text-blue sm-dg pointer" @click="consultantClick">
              我要对公转账汇款
            </div>
          </div>
        </div>
      </div>

      <consultant ref="consultant"></consultant>
      <mask-serve ref="maskServe"></mask-serve>
    </div>
  </div>
</template>

<script>
import consultant from "../../../components/consultant.vue";
import maskServe from "../../../components/server.vue"
import api from "../../../api/user";
export default {
  components: { consultant,maskServe },
  props: {
    rechargeList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    exchangeObj:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  data() {
    return {
      status: false,
      statusOne: false,
      activeIndex: 0,
      payActiveIndex: 1,
      query: {
        type: "coin",
        num: "",
        pay_type: 3,
      },
      buyObj: null,
      setObj:null
      
    };
  },

  created() {
    this.setObj=JSON.parse(localStorage.getItem('setObj'))
  },
  watch: {
    rechargeList(val) {
      this.query.num = val[0].coin;
    },
  },
  mounted() {},

  methods: {
 
    serveClick(){
      
      this.$refs.maskServe.show()
    },
    // 隐藏
    hide() {
      this.status = false;
      this.$emit("hide");
    },
    // 显示
    show() {
      this.payActiveIndex=1
      this.status = true;
    },
    buyService() {
      api.buyService(this.query).then((res) => {
        if (res.code == 200) {
          this.buyObj = res.data;
          this.$emit('buyClick',res.data)
        }
      });
    },
    // 对公转帐
    consultantClick() {
      this.$refs.consultant.show();
    },
    activeClick(index, num) {
      this.activeIndex = index;
      this.query.num = num;
      // this.buyService();
    },
    payClick(index) {
        if(index==3 && this.setObj.pay.alipay!=1){
        this.$util.msg('支付宝支付未开启，请选择微信支付','error')
        return
      }else if(index==4 && this.setObj.pay.wx!=1){
        this.$util.msg('微信支付未开启，请选择支付宝支付','error')
        return
      }
      this.payActiveIndex = index;
      this.query.pay_type = index;
      this.buyService();
    },
    zfbClick() {
      let divForm = document.getElementsByTagName("divform");
      if (divForm.length) {
        document.body.removeChild(divForm[0]);
      }
      const div = document.createElement("divform");
      div.innerHTML = this.buyObj.payment;
      document.body.appendChild(div);
      document.forms[0].setAttribute("target", "_blank");
      document.forms[0].submit();
    },
  },
};
</script>
<style lang="less" scoped>
.recharge {
  width: 27.5625rem;
  background: #fefefe;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  padding: 1.3125rem 1.3125rem 1.625rem 1.5rem;
  .recharge-title {
    width: 100%;
    padding-left: 0.3125rem;
    align-items: flex-start;
    margin-bottom: 0.75rem;
    div {
      font-size: 1rem;
      padding-top: 0.1875rem;
      img {
        width: 1.0625rem;
        height: 1.1875rem;
        margin-right: 0.375rem;
      }
    }
  }
  .recharge-text {
    color: #9fa3b0;
    font-size: 0.875rem;
    padding-left: 0.3125rem;
  }
  .recharge-item {
    width: 100%;
    margin-right: 0.1875rem;
    padding: 0.375rem 0.4375rem 0 0.375rem;

    .item-center {
      width: 100%;
      height: 18.5625rem;
      background: #ffffff;
      box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.08);
      padding: 1.3125rem 0.75rem 1.25rem 1.0625rem;
      margin-bottom: 2rem;
      .item {
        width: 100%;
        height: 3.25rem;
        border: 1px solid #e4e7ed;
        color: #676d7e;
        font-size: 0.875rem;
        span {
          color: #fc7a4c;
          font-size: 1.3125rem;
        }
      }
      .item-last {
        padding: 0 1.1875rem 0 0.75rem;
      }
      .active-class {
        background: rgba(102, 109, 128, 0.3);
      }
    }
    .tab-pay {
      width: 100%;
      margin-bottom: 10px;
      div {
        width: 4.5625rem;
        height: 2.125rem;
        border: 0.0625rem solid #dbdfe4;
        border-radius: 0.125rem;
        color: #9fa3b0;
        margin-right: 1.25rem;
      }
      .pay-item-active {
        border: 1px solid #126bf9;
        color: #126bf9;
      }
    }
    .img-sm {
      width: 7.4375rem;
      height: 7.375rem;
      margin-bottom: 1.125rem;
    }
    .sm-text {
      color: #a3a3a3;
      font-size: 0.8125rem;
    }
    .sm-zfb {
      margin-top: 1.8125rem;
      img {
        width: 1rem;
        height: 1rem;
      }
      .wx-img {
        margin: 0 0.3125rem;
      }
    }
    .sm-xy {
      color: #666d80;
      font-size: 0.8125rem;
      margin: 0.9375rem 0 1.375rem 0;
    }
    .sm-dg {
      font-weight: bold;
      font-size: 0.9375rem;
    }
  }
}
</style>
