<template>
  <div>
    <div v-if="status">
      <div
        class="position-fixed top-0 left-0 right-0 bottom-0"
        style="background-color: rgba(0, 0, 0, 0.5); z-index: 99"
        @click.stop="hide"
      ></div>
      <div class="recommend animated faster fadeIn">
        <div class="row-end">
          <i class="el-icon-close pointer" @click="hide"></i>
        </div>
        <div class="text-main font-light">兑换</div>
        <div class="text-main font-normal recommend-title">
          您当前选择兑换的是
          <span class="text-blue font-lighter">人才推荐 </span>服务
        </div>
        <div class="font-normal recommend-text">
          发布职位，即可获得精准人才（有余额情况下，每个职位每日推荐2个人才）
        </div>
        <div class="recommend-center direction-between font-normal">
          <div
            class="item flex-center pointer"
            v-for="(item, index) in pushList"
            :key="index"
            :class="activeIndex == index ? 'active-class' : ''"
            @click="activeClick(index, item.num)"
          >
            {{ item.price }}元 <span>{{ item.num }}</span> 位匹配人才
          </div>
          <div class="item item-price space-between">
            <div>支付金额</div>
            <span v-if="pushList.length > 0">￥{{ pushList[activeIndex].price }}</span>
          </div>
        </div>
        <div class="row-center pay-way font-normal">
          <div class="text-main">支付方式：</div>
          <div
            class="pay-item flex-center pointer"
            :class="[payindex == item.type ? 'pay-item-active' : '']"
            v-for="(item, index) in payList"
            :key="index"
            @click="payClick(item.type)"
          >
            {{ item.name }}
          </div>
        </div>
        <div v-if="payindex == 1" class="row-center pay-account font-normal">
          <div class="text-main">支付账户：</div>
          <div class="text-main">{{ userDetail.name }} {{ userDetail.phone }}</div>
        </div>
        <div class="consume font-normal" v-if="payindex == 1">
          消耗 ：{{ beanNum }}纺职豆
        </div>
        <div class="pay-immediately row-end">
          <div
            class="flex-center text-white bg-blue font-normal pointer"
            @click="immediatelyPay"
          >
            立即支付
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {},
  computed: {
    // 2. 解构状态作为计算属性
    ...mapState(["userDetail"]),
  },
  props: {
    pushList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    coin: {
      type: [String, Number],
    },
    exchangeObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      status: false,
      //   "纺职豆", "支付宝", "微信"
      payList: [
        {
          type: 1,
          name: "纺职豆",
        },
        {
          type: 3,
          name: "支付宝",
        },
        {
          type: 4,
          name: "微信",
        },
      ],
      payindex: 1,
      activeIndex: 0,
      query: {
        num: 0,
        type: "push ",
        pay_type: "",
      },
      beanNum: "",
      setObj:null
    };
  },
  created() {
    this.setObj=JSON.parse(localStorage.getItem('setObj'))
  },

  mounted() {},
  watch: {
    status() {
      this.payindex = 1;
    },
    pushList(val) {
      this.query.num = val[0].num;
    },
    exchangeObj: {
      handler(newVal, oldVal) {
        if(newVal){
          this.beanNum = (this.pushList[0].price * newVal.coin) / newVal.price;
        }
      },
      immediate: true,
      // 这里是关键，代表递归监听 demo 的变化
      deep: true,
    },
  },
  methods: {
    // 隐藏
    hide() {
      this.status = false;
      this.$emit("hide");
    },
    // 显示
    show() {
      this.status = true;
    },
    // 支付方式
    payClick(index) {
      if(index==3 && this.setObj.pay.alipay!=1){
        this.$util.msg('支付宝支付未开启，请选择其他方式支付','error')
        return
      }else if(index==4 && this.setObj.pay.wx!=1){
        this.$util.msg('微信支付未开启，请选择其他方式支付','error')
        return
      }
      this.payindex = index;
      this.query.pay_type = index;
    },
    // 立即支付
    immediatelyPay() {
      if (this.query.pay_type == "") {
        this.query.pay_type = 1;
      }
      if (this.query.pay_type == 1) {
        if (this.coin * this.exchangeObj.price < this.pushList[this.activeIndex].price) {
          this.$util.msg("纺职豆不足，请选择其他方式支付", "error");
          return;
        }
      }
      this.hide();
      this.$emit("immediatelyPay", this.query);
    },
    activeClick(index, num) {
      this.activeIndex = index;
      this.query.num = num;
      this.beanNum = (this.pushList[index].price * this.exchangeObj.coin) / this.exchangeObj.price;
    },
  },
};
</script>
<style lang="less" scoped>
.recommend {
  width: 30.125rem;
  background: #ffffff;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  padding: 1.125rem 1.3125rem 1.875rem 1.8125rem;
  .recommend-title {
    margin-top: 0.5rem;
    margin-bottom: 1.875rem;
  }
  .recommend-text {
    color: #9fa3b0;
    line-height: 1.125rem;
    margin-bottom: 1.6875rem;
    padding-right: 1.5rem;
  }
  .recommend-center {
    margin: 0 auto;
    margin-right: 1.25rem;
    width: 25.625rem;
    height: 18rem;
    background: #ffffff;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.08);
    padding: 1.3125rem 0.75rem 1.25rem 1.25rem;
    margin-bottom: 1.4375rem;
    .item {
      width: 100%;
      height: 3.25rem;
      border: 1px solid #e4e7ed;
      color: #676d7e;
      span {
        color: #fc7a4c;
        font-size: 1.3125rem;
        display: inline-block;
        margin: 0 0.625rem;
      }
    }
    .item-price {
      padding: 0 1.3125rem;
      span {
        margin: 0;
      }
    }
    .active-class {
      background: rgba(102, 109, 128, 0.3);
    }
  }
  .pay-way {
    margin-bottom: 1.25rem;
    .pay-item {
      width: 4.5625rem;
      height: 2.125rem;
      border: 1px solid #dbdfe4;
      border-radius: 2px;
      color: #9fa3b0;
      margin-left: 1.25rem;
    }
    .pay-item-active {
      border: 1px solid #126bf9;
      color: #126bf9;
    }
  }
  .pay-account {
    margin-bottom: 1.25rem;
    div:last-child {
      width: 290px;
      height: 34px;
      border: 1px solid #e3e7ed;
      border-radius: 2px;
      line-height: 2rem;
      padding-left: 12px;
      margin-left: 20px;
    }
  }
  .consume {
    color: #9fa3b0;
    margin-left: 5.625rem;
    margin-bottom: 30px;
  }
  .pay-immediately {
    div {
      width: 7.9375rem;
      height: 2.125rem;
    }
  }
}
</style>
