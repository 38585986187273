<template>
  <div>
    <div v-if="status">
      <div
        class="position-fixed top-0 left-0 right-0 bottom-0"
        style="background-color: rgba(0, 0, 0, 0.5); z-index: 99"
        @click.stop="hide"
      ></div>
      <div class="el-invoice">
        <div class="invoice-img row-end">
          <img src="../../../assets/forget/15.png" class="pointer" @click="hide" alt="" />
        </div>
        <div class="text-main font-light">开具发票</div>
        <div class="invoice-text text-main font-lighter">
          <div>开票类型</div>
          <div class="font-normal">增值税电子普通发票</div>
        </div>
        <div class="invoic-tab row-center text-main font-normal">
          <div>抬头类型</div>
          <div
            class="tab-class flex-center pointer"
            :class="invoiceTab == 1 ? 'invocie-class' : ''"
            @click="invoiceTabClick(1)"
          >
            企业单位
          </div>
          <div
            class="tab-class flex-center pointer"
            :class="invoiceTab == 2 ? 'invocie-class' : ''"
            @click="invoiceTabClick(2)"
          >
            个人/非企业
          </div>
        </div>
        <div class="item-invoic">
          <div class="item-one row-center font-normal text-main">
            <div class="row-center item-one-left">
              <div class="left-text"><span>*</span>发票抬头：</div>
              <div class="item-one-input">
                <el-input placeholder="请输入内容" v-model="query.header"> </el-input>
              </div>
            </div>
            <div class="row-center item-one-left item-one-left-one">
              <div class="left-text"><span>*</span>发票金额：</div>
              <div class="item-one-input">
                <el-input placeholder="请输入内容" v-model="input1" :disabled="true">
                </el-input>
              </div>
            </div>
          </div>
          <div class="item-one row-center font-normal text-main">
            <div class="row-center item-one-left" v-if="invoiceTab == 1">
              <div class="left-text"><span>*</span>纳税人识别号：</div>
              <div class="item-one-input-one">
                <el-input placeholder="请输入内容" v-model="query.taxpayer_number">
                </el-input>
              </div>
            </div>
            <div class="row-center item-one-left" v-else>
              <div class="left-text"><span>*</span>发票接受邮箱：</div>
              <div class="item-one-input-one">
                <el-input placeholder="请输入内容" v-model="query.eamil"> </el-input>
              </div>
            </div>
            <div class="row-center item-one-left item-one-left-one">
              <div class="left-text"><span>*</span>发票内容：</div>
              <div class="item-one-input">
                <el-input placeholder="请输入内容" v-model="input4" :disabled="true">
                </el-input>
              </div>
            </div>
          </div>
          <div class="item-one row-center font-normal text-main" v-if="invoiceTab == 1">
            <div class="row-center item-one-left">
              <div class="left-text"><span>*</span>发票接受邮箱：</div>
              <div class="item-one-input-one">
                <el-input placeholder="请输入内容" v-model="query.eamil"> </el-input>
              </div>
            </div>
          </div>
          <div class="row-end invoic-button font-normal">
            <div class="flex-center text-blue pointer" @click="hide">取消</div>
            <div class="flex-center text-white bg-blue pointer" @click="submitClick">
              确定并提交
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import api from "../../../api/enterprises"
export default {
  computed: {
    ...mapState(["entDetail", "userDetail"]),
  },
  components: {},
  props: {
    invoiceObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      status: false,
      invoiceTab: 1,
      input4: "信息技术服务费*网络招聘费",
      query: {
        header: "", //发票抬头
        header_type: 1, //抬头类型 1 企业 2
        taxpayer_number: "", //纳税人识别号 抬头为1是必传
        eamil: "", //发票接收邮箱
        order_id: "", //订单ID
      },
    };
  },

  created() {
    
  },
  watch: {
    invoiceObj: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.query.header = this.entDetail.ent_name;
          this.input1 = "￥" + newVal.amount;
          this.query.order_id = newVal.id;
        }
      },
      immediate: true,
      // 这里是关键，代表递归监听 demo 的变化
      deep: true,
    },
  },
  mounted() {},

  methods: {
    hide() {
      this.status = false;
    },
    show() {
      this.status = true;
    },
    invoiceTabClick(i) {
      this.invoiceTab = i;
      this.query.header_type = i;
      this.query.taxpayer_number=''
    },
    submitClick() {
      if (this.query.header.trim() == "") {
        this.$util.msg("请输入发票抬头", "warning");
        return;
      } else if (!this.$util.checkStr(this.query.eamil, 'email')) {
        this.$util.msg("请输入正确的邮箱", "warning");
        return;
      }
      if (this.invoiceTab == 1) {
        if (this.query.taxpayer_number.trim() == "") {
          this.$util.msg("请输入纳税人识别号", "warning");
          return;
        }
      } else {
        this.query.taxpayer_number = "";
      }
      api.addInvoice(this.query).then(res=>{
        if(res.code==200){
          this.hide()
          this.$util.msg(res.msg)
          this.$emit('addInvoice')
        }
      })
    },
  },
};
</script>
<style lang="less" scoped>
.el-invoice {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 887px;
  background: #ffffff;
  border-radius: 6px;
  padding: 23px 23px 37px 30px;
  .invoice-img {
    width: 100%;
    height: 11px;
    img {
      width: 11px;
      height: 100%;
    }
  }
  .invoice-text {
    margin-bottom: 30px;
    margin-top: 40px;
    div:last-child {
      color: #9fa3b0;
      margin-top: 6px;
    }
  }
  .invoic-tab {
    margin-bottom: 30px;
    .tab-class {
      margin-left: 20px;
      width: 114px;
      height: 33px;
    }
    .invocie-class {
      border: 1px solid #126bf9;
      color: #126bf9;
    }
  }
  .item-invoic {
    margin-right: 7px;
  }
  .item-one {
    margin-bottom: 30px;
    .item-one-left {
      margin-right: 30px;
    }
    .item-one-left-one {
      margin-right: 0;
    }
  }
  .item-one:last-child {
    margin-bottom: 0;
  }
  .left-text {
    span {
      color: #f56f1f;
    }
  }
  .item-one-input {
    width: 318px;
    /deep/.el-input__inner {
      height: 34px;
      line-height: 34px;
    }
  }
  .item-one-input-one {
    width: 290px;
    /deep/.el-input__inner {
      height: 34px;
      line-height: 34px;
    }
  }
  .invoic-button {
    margin-top: 30px;
    width: 100%;
    div {
      width: 104px;
      height: 34px;
    }
    div:first-child {
      margin-right: 12px;
      border: 1px solid #4b7dde;
    }
  }
}
</style>
