<template>
  <div>
    <div v-if="status">
      <div
        class="position-fixed top-0 left-0 right-0 bottom-0"
        style="background-color: rgba(0, 0, 0, 0.5); z-index: 99"
        @click.stop="hide"
      ></div>
      <div class="el-contract">
        <div class="row-end heard-img">
          <img src="../../../assets/glxt/25.png" class="pointer" @click="hide" alt="" />
        </div>
        <div class="contract-center text-main">
          <!-- <div class="center-title">招聘协议</div> -->
          <div class="el-center-text">
            <iframe
              :src="pdfUrl"
              type="application/x-google-chrome-pdf"
              width="100%"
              frameborder="0"
              scrolling="auto"
              height="100%"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    resume_url: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      status: false,
      pdfUrl: "",
    };
  },

  created() {},
  watch: {
    resume_url: {
      handler(newVal, oldVal) {
        this.pdfUrl = newVal + "#toolbar=0";
      },
      immediate: true,
      // 这里是关键，代表递归监听 demo 的变化
      deep: true,
    },
  },
  mounted() {},

  methods: {
    hide() {
      this.status = false;
    },
    show() {
      this.status = true;
    },
  },
};
</script>
<style lang="less" scoped>
.el-contract {
  width: 1000px;
  max-height: 890px;
  background: #ffffff;
  padding: 22px 0 20px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  .heard-img {
    width: 100%;
    padding-right: 24px;
    margin-bottom: 34px;
    img {
      width: 17px;
      height: 17px;
    }
  }
  .contract-center {
    width: 100%;
    padding: 0 43px;
    .center-title {
      text-align: center;
      margin-bottom: 46px;
      font-size: 21px;
    }
    .el-center-text {
      text-align: justify;
      width: 100%;
      height: 750px;
      font-size: 15px;
      line-height: 22px;
    }
  }
}
</style>
